/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from "react";
import { Pet, PetRewards, PetScores, Reward, Score, Totals } from "../../../common/types";
import { WalletSelector } from "./WalletSelector";
import { sortPets } from "../../helpers/helpers";

function ToggleSwitch({
  selectedViewer,
  setSelectedViewer,
}: {
  selectedViewer: string;
  setSelectedViewer: (value: string) => void;
}) {
  const isTimersSelected = selectedViewer === "statViewer";

  return (
    <div className="flex items-center">
      <div
        className={`relative h-6 w-10 rounded-full border-2 border-brown bg-white ${
          isTimersSelected ? "bg-white" : ""
        }`}
        onClick={() => setSelectedViewer(isTimersSelected ? "timerViewer" : "statViewer")}
      >
        <div
          className={`h-4 w-4 m-[.135rem]  rounded-full bg-brown ${
            isTimersSelected ? "transform translate-x-full" : ""
          }`}
        ></div>
      </div>
    </div>
  );
}


export function TopBar({
  pets,
  setPets,
  setTotals,
  selectedViewer,
  setSelectedViewer,
  walletAddress,
  setWalletAddress,
  setRewards,
  setScores,
  setTopCats,
  setPetRewards,
  setPetScores,
  setEthBalance,
  setPgoldBalance,
  setFpBalance,
  isLoading,
  setRefreshTrigger,
  setIsLoading,
  sortKey,
  setSortKey,
  sortOrder,
  setSortOrder,
  isPhone,
}: {
  pets: Pet[];
  setPets: React.Dispatch<React.SetStateAction<Pet[]>>;
  setTotals: React.Dispatch<React.SetStateAction<Totals>>;
  selectedViewer: string;
  setSelectedViewer: React.Dispatch<React.SetStateAction<string>>;
  walletAddress: string;
  setWalletAddress: (address: string) => void;
  setTopCats: React.Dispatch<React.SetStateAction<Pet[]>>;
  setRewards: React.Dispatch<React.SetStateAction<Reward[]>>;
  setScores: React.Dispatch<React.SetStateAction<Score[]>>;
  setPetRewards: React.Dispatch<React.SetStateAction<PetRewards>>;
  setPetScores: React.Dispatch<React.SetStateAction<PetScores>>;
  setEthBalance: React.Dispatch<React.SetStateAction<string>>;
  setPgoldBalance: React.Dispatch<React.SetStateAction<string>>;
  setFpBalance: React.Dispatch<React.SetStateAction<string>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setRefreshTrigger: React.Dispatch<React.SetStateAction<number>>;
  sortKey: keyof Pet;
  setSortKey: React.Dispatch<React.SetStateAction<keyof Pet>>;
  sortOrder: "asc" | "desc";
  setSortOrder: React.Dispatch<React.SetStateAction<"asc" | "desc">>;
  isPhone: boolean;
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isTouchDevice = "ontouchstart" in window;
  const menuIconPath = "/imgs/menu.svg";
  const closeIconPath = "/imgs/close.svg";
  const svgMap = {
    toppets: "/imgs/paw.svg",
    topcats: "/imgs/cat.svg",
    topattack: "/imgs/sword.svg",
    topdefense: "/imgs/shield.svg",
    topoldpets: "/imgs/hourglass.svg",
    charts: "/imgs/chart.svg",
    activityfeed: "/imgs/rss.svg",
    store: "/imgs/store.svg",
    legend: "/imgs/legend.svg",
    donate: "/imgs/heart.svg",
    stats: "/imgs/passBadge.png",
    changelog: "/imgs/clipboard.svg",
    topstars: "/imgs/star.svg",
    topdragons: "/imgs/dragon.svg",
    topdogs: "/imgs/dog.svg",
    topsheep: "/imgs/sheep.svg",
    toppepes: "/imgs/frog.svg",
    topmonkeys: "/imgs/monkey.svg",
    toppenguins: "/imgs/penguin.svg",
    toppandas: "/imgs/panda.svg",
  };
  let viewText;
  switch (selectedViewer) {
    case "timerViewer":
      viewText = "Pet Timers";
      break;
    case "statViewer":
      viewText = "Pet Stats";
      break;
    case "toppets":
      viewText = "Top 100 Pets";
      break;
    case "topcats":
      viewText = "Top 100 Cats";
      break;
    case "topoldpets":
      viewText = "Top 100 Old Pets"
      break;
    case "charts":
      viewText = "Charts";
      break;
    case "activityfeed":
      viewText = "Activity Feed";
      break;
    case "store":
      viewText = "Fren Shop"
      break;
    case "legend":
      viewText = "Legend";
      break;
    case "donate":
      viewText = "Donate";
      break;
    case "stats":
      viewText = "Stats";
      break;
    case "changelog":
      viewText = "Change Log";
      break;
    case "topstars":
      viewText = "Top 100 Keepers";
      break;
    case "topdragons":
      viewText = "Dragons";
      break;
    case "topsheep":
        viewText = "Top 100 Sheep";
        break;
    case "topdogs":
      viewText = "Top 100 Dogs";
      break;
    case "toppandas":
      viewText = "Top 100 Pandas";
      break;
    case "topmonkeys":
      viewText = "Top 100 Monkeys";
      break;
    case "toppepes":
      viewText = "Top 100 Pepes";
      break;
    case "toppenguins":
      viewText = "Penguins";
      break;
    default:
      viewText = "View";
  }

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  useEffect(() => {
    setPets((prevPets) => sortPets(prevPets, sortKey, sortOrder));
  }, [sortKey, sortOrder]);
  

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    setSortKey("score");
    setSortOrder("desc");
  }, [walletAddress]);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0 && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMenuOpen]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const refreshButtonView = [
    "statViewer",
    "timerViewer"
  ].includes(selectedViewer);

  const hideOnView = [
    "toppets",
    "topattack",
    "topdefense",
    "charts",
    "topcats",
    "activityfeed",
    "legend",
    "donate",
    "topoldpets",
    "store",
    "stats",
    "changelog",
    "topstars",
    "topdragons",
    "toppepes",
    "topsheep",
    "toppandas",
    "topmonkeys",
    "topdogs",
    "toppenguins"
  ].includes(selectedViewer);

  return (
    <div className="fixed z-40 w-full">
      <header className="relative z-40 flex h-16 justify-between bg-green pb-1">
        <img
          src="/imgs/fpAnalyticaLogoBD.png"
          alt=""
          className="mt-2 h-14 w-14"
          onClick={() => setSelectedViewer("timerViewer")}
        />
        <WalletSelector
          pets={pets}
          setPets={setPets}
          selectedViewer={selectedViewer}
          setRewards={setRewards}
          walletAddress={walletAddress}
          setWalletAddress={setWalletAddress}
          setTotals={setTotals}
          setScores={setScores}
          setPetRewards={setPetRewards}
          setPetScores={setPetScores}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          isPhone={isPhone}
        />
        <img
          src={isMenuOpen ? closeIconPath : menuIconPath}
          alt={isMenuOpen ? "Close Menu" : "Open Menu"}
          className="mr-2 mt-[1.55rem] h-6 w-6 cursor-pointer"
          onClick={() => setIsMenuOpen((prev) => !prev)}
        />
      </header>

      <div
        className="relative top-0  z-20 mx-auto flex flex-col justify-center border-b-2 border-t-2 border-brown bg-green pb-1 shadow-lg"
        style={{ minWidth: "390px" }}
      >
        <div
          className={`mb-1 ml-5 mr-4 mt-2 flex items-center text-lg font-semibold ${selectedViewer === "statViewer" || selectedViewer === "timerViewer" ? "justify-between" : "justify-center"}`}
        >
          <div className="flex">
            {viewText}
            {svgMap[selectedViewer as keyof typeof svgMap] && (
              <img
                src={svgMap[selectedViewer as keyof typeof svgMap]}
                alt={viewText}
                className={`ml-2 h-5 w-5 mt-[.2rem] mr-1 ${selectedViewer === "topattack" ? "scale-x-[-1] transform" : ""}`}
              />
            )}
              {refreshButtonView && (
              <button
                className={`btn-active ml-2 mt-[0.1rem] h-[1.55rem] w-10 rounded-md border-2 border-brown bg-white`}
                onClick={ (() => setRefreshTrigger((prev) => prev + 1))}
              >
                {isLoading && pets.length !== 0 && !isPhone ? (
                  <img src="/imgs/refresh.svg" alt="" className="spin h-4 w-12" />
                ) : (
                  <img src="/imgs/refresh.svg" alt="" className="h-4 w-12" />
                )}
              </button>
            )}
          </div>
          {!hideOnView && (
              <div className="row flex">
                <ToggleSwitch
                selectedViewer={selectedViewer}
                setSelectedViewer={setSelectedViewer}
                />
                <select
                  value={sortKey}
                  onChange={(e) => setSortKey(e.target.value as keyof Pet)}
                  className="ml-[.55rem] max-w-20 rounded-md border-2 border-brown bg-white text-sm [box-shadow:inset_0_-1px_#342e2e]"
                  style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                >
                  <optgroup label="General">
                    <option value="score">Score</option>
                    <option value="id">Id</option>
                    <option value="name">Name</option>
                    <option value="petAgeInDays">Age</option>
                    <option value="petType">Dna</option>
                    <option value="rewards">Rewards</option>
                    <option value="stars">Stars</option>
                    <option value="actualCarrots">Carrots</option>
                    <option value="availableGacha">Available Gacha</option>
                    <option value="nextGacha">Next Gacha</option>
                    <option value="gachaEfficiency">Gacha Eff.</option>
                    <option value="passHolder"> Pass Holder </option>
                    <option value="explorerPoints"> Explorer Points</option>
                  </optgroup>
                  
                  <optgroup label="Combat">
                    <option value="attackPoints">Attack</option>
                    <option value="defensePoints">Defense</option>
                    <option value="atkLevel">Atk Lvl</option>
                    <option value="defLevel">Def Lvl</option>
                    <option value="petWins">Wins</option>
                    <option value="winsAvailable">Training Wins</option>
                    <option value="monstersCaught">Monsters</option>
                  </optgroup>
                  
                  <optgroup label="Timers">
                    <option value="timeUntilStarving">Hunger</option>
                    <option value="timeUntilAttack">Bonk</option>
                    <option value="timeUntilWheel">Spin</option>
                    <option value="atkTrainingEndsTimestamp">Atk Training</option>
                    <option value="defTrainingEndsTimestamp">Def Training</option>
                  </optgroup>
                </select>

                <button onClick={toggleSortOrder} className="ml-2 mr-[.2rem] mt-[0.1rem]">
                  <img
                    src={sortOrder === "desc" ? "/imgs/descending.svg" : "/imgs/ascending.svg"}
                    alt={sortOrder === "desc" ? "Descending" : "Ascending"}
                    className="h-4 w-4"
                  />
                </button>
              </div>
            )}
        </div>
      </div>
      <div
        className={`fixed right-0 top-[7rem] z-10 h-[calc(100vh-7rem)] w-[12.5rem] transform overflow-y-auto border-l-[1.5px] border-brown bg-green p-4 shadow-xl transition-transform ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        }`}
        tabIndex={-1}
        onMouseLeave={() => !isTouchDevice && setIsMenuOpen(false)}
      >
        <ul>
        <li>
            <button
              className={`block rounded-lg border-brown px-2 py-2 text-brown hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "timerViewer" || selectedViewer === "statViewer" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
              onClick={() => setSelectedViewer("timerViewer")}
            >
              <div className="flex flex-row">
                <img src="/imgs/paw.svg" alt="" className="mr-[.6rem] mt-[0.11rem] h-5 w-5" />
                Pets
              </div>
            </button>
          </li>
          <li>
            <button
              className={`mt-2 mb-3 block rounded-lg border-brown px-2 py-2 text-brown hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "charts" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
              onClick={() => setSelectedViewer("charts")}
            >
              <div className="flex flex-row">
                <img src="/imgs/chart.svg" alt="" className="mr-2 mt-[0.11rem] h-5 w-5" />
                Charts
              </div>
            </button>
          </li>
          <hr className="border-brown w-[calc(100%+2rem)] -mx-4" />
          <ul className="relative">
            <li>
              <button
                className={`block rounded-lg border-brown mt-2 px-2 py-2 text-brown hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "stats" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                onClick={() => setSelectedViewer("stats")}
              >
                <div className="flex flex-row">
                  <img src="/imgs/passBadge.png" alt="" className="mr-[.6rem] mt-[0.11rem] h-5 w-5" />
                  Stats
                </div>
              </button>
            </li>
            <li>
            <button
              className={`mt-2 block rounded-lg border-brown px-2 py-2 p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "store" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
              onClick={() => {
                setSelectedViewer("store");
              }}
            >
              <div className="flex flex-row text-brown">
                <img src="/imgs/store.svg" alt="" className="mr-2 mt-[0.1rem] h-5 w-5" />
                Fren Shop
              </div>
            </button>
          </li>
            <li className="mt-2">
              <button
                type="button"
                className={`group flex w-full items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "toppets" || selectedViewer === "topcats" || selectedViewer === "topdefense" || selectedViewer === "topattack" || selectedViewer === "topoldpets" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                aria-controls="dropdown-leaderboard"
                onClick={toggleDropdown}
              >
                <div className="flex flex-row text-brown">
                  <img src="/imgs/rank.svg" alt="" className="mr-[.6rem] mt-[0.05rem] h-5 w-5" />
                  Leaderboards
                </div>
              </button>
              <ul
                id="dropdown-leaderboard"
                className={`${dropdownOpen ? "block" : "hidden"} space-y-2 py-2`}
              >
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "toppets" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("toppets");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/paw.svg" alt="" className="mr-2 mt-[0.25rem] h-4 w-4" />
                      Pets
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "topoldpets" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("topoldpets");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/hourglass.svg" alt="" className="mr-2 mt-[0.25rem] h-4 w-4" />
                      Old Pets
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "topdogs" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("topdogs");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/dog.svg" alt="" className="mr-2 mt-[0.25rem] h-4 w-4" />
                      Dogs
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "topsheep" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("topsheep");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/sheep.svg" alt="" className="mr-2 mt-[0.25rem] h-4 w-4" />
                      Sheep
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "topmonkeys" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("topmonkeys");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/monkey.svg" alt="" className="scale-x-[-1] transform mr-2 mt-[0.25rem] h-4 w-4" />
                      Monkeys
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "toppandas" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("toppandas");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/panda.svg" alt="" className="mr-2 mt-[0.25rem] h-4 w-4" />
                      Pandas
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "toppepes" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("toppepes");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/frog.svg" alt="" className="mr-2 mt-[0.25rem] h-4 w-4" />
                      Pepes
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "toppenguins" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("toppenguins");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/penguin.svg" alt="" className="mr-2 mt-[0.25rem] h-4 w-4 scale-x-[-1] transform" />
                      Penguins
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "topcats" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("topcats");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/cat.svg" alt="" className="mr-2 mt-[0.25rem] h-4 w-4" />
                      Cats
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "topdragons" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("topdragons");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img src="/imgs/dragon.svg" alt="" className="mr-2 mt-[0.25rem] h-4 w-4" />
                      Dragons
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    className={`w-26 group ml-9 flex items-center rounded-lg border-brown p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "topstars" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
                    onClick={() => {
                      setSelectedViewer("topstars");
                      toggleDropdown();
                    }}
                  >
                    <div className="flex flex-row text-brown">
                      <img
                        src="/imgs/star.svg"
                        alt=""
                        className="mr-[.35rem] mt-[0.25rem] h-4 w-4"
                      />
                      Stars
                    </div>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
          <li>
            <button
              className={`mt-2 mb-3 block rounded-lg border-brown px-2 py-2 p-2 text-base text-brown transition duration-75 hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "activityfeed" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
              onClick={() => {
                setSelectedViewer("activityfeed");
              }}
            >
              <div className="flex flex-row text-brown">
                <img src="/imgs/rss.svg" alt="" className="mr-[.7rem] mt-[0.18rem] h-4 w-4" />
                Activity Feed
              </div>
            </button>
          </li>
          <hr className="border-brown w-[calc(100%+2rem)] -mx-4" />
          <li>
            <button
              className={`mt-3 block rounded-lg border-brown px-2 py-2 text-brown hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "legend" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
              onClick={() => setSelectedViewer("legend")}
            >
              <div className="flex flex-row">
                <img src="/imgs/legend.svg" alt="" className="mr-2 mt-[0.12rem] h-5 w-5" />
                Legend
              </div>
            </button>
          </li>
          <li>
            <button
              className={`mt-2 block rounded-lg border-brown px-2 py-2 text-brown hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "changelog" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
              onClick={() => setSelectedViewer("changelog")}
            >
              <div className="flex flex-row">
                <img src="/imgs/clipboard.svg" alt="" className="mr-2 mt-[0.12rem] h-5 w-5" />
                Change Log
              </div>
            </button>
          </li>
          <li>
            <button
              className={`mt-2 block rounded-lg border-brown px-2 py-2 text-brown hover:border hover:bg-white hover:[box-shadow:inset_0_-1px_#342e2e] ${selectedViewer === "donate" ? "border border-brown bg-gray-100 [box-shadow:inset_0_-1px_#342e2e]" : "hover:bg-white"}`}
              onClick={() => setSelectedViewer("donate")}
            >
              <div className="flex flex-row">
                <img src="/imgs/heart.svg" alt="" className="mr-2 mt-[0.12rem] h-5 w-5" />
                Donate
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
