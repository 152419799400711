import React, { useEffect, useState } from "react";
import { CP, getProgress, getTimeSince } from "../../helpers/helpers";

export function Meter({
  startSec,
  endSec,
  imgSrc,
  imgClass,
  isDepleting = false,
  showTooltip = false,
  reverseTooltip = false,
  walletAddress,
  onClick,
  isTraining = false,
  trainingEndSec,
  availableBonks,
  hibernating,
}: {
  startSec: number;
  endSec: number;
  imgSrc: string;
  imgClass: React.HTMLAttributes<HTMLImageElement>["className"];
  isDepleting?: boolean;
  showTooltip?: boolean;
  reverseTooltip?: boolean;
  walletAddress: string;
  onClick?: () => void;
  isTraining?: boolean;
  trainingEndSec?: number;
  availableBonks?: number;
  hibernating?: boolean;
}) {
  const [progress, setProgress] = useState(getProgress(startSec, endSec, isDepleting));
  const [hover, setHover] = useState(false);
  const [tooltipString, setTooltipString] = useState("");
  const isTouchDevice = "ontouchstart" in window;

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);

      if (isTraining && trainingEndSec && now > trainingEndSec) {
        setProgress(100);
      } else if (!isTraining && trainingEndSec && now > trainingEndSec){
        setProgress(0);
      } else {
        setProgress(getProgress(startSec, endSec, isDepleting));
      }
    }, 1000);

    if (!showTooltip || isTouchDevice) {
      return () => clearInterval(interval);
    }

    const tooltipInterval = setInterval(() => {
      setTooltipString(getTimeSince(reverseTooltip ? endSec : startSec));
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(tooltipInterval);
    };
  }, [startSec, endSec, isDepleting, isTraining, trainingEndSec, showTooltip, reverseTooltip]);

  const isFull = progress >= 100;
  let progressBarClass = "bg-brown";
  let dying = false;
  if (hibernating) {
    progressBarClass = "bg-red";
  } else if (isFull) {
    progressBarClass = "bg-green";
  } else if (isDepleting) {
    if (progress <= 5 && progress >= 0.01) {
      progressBarClass = "bg-red";
      dying = true;
    } else if (progress <= 20) {
      progressBarClass = "bg-red";
    }
  }

  return (
    <div
      className="flex w-full items-center space-x-2"
      onMouseEnter={() => !isTouchDevice && setHover(true)}
      onMouseLeave={() => !isTouchDevice && setHover(false)}
      onClick={onClick}
    >
      <div className="h-6 w-6">
        {CP.includes(walletAddress) && showTooltip && hover && (
          <div className="absolute mt-[-28px] ml-[-28px] w-32 justify-center rounded-md border-2 border-brown bg-white p-1 text-sm shadow-md">
            {tooltipString}
          </div>
        )}
        <img src={imgSrc} alt="" className={imgClass} />
      </div>
      <div className="flex h-[50%] grow flex-row rounded-lg border-2 border-brown bg-white">
        <div className={`rounded-lg ${progressBarClass}`} style={{ width: `${progress}%` }}></div>
      </div>
      {dying && <div className=" mb-[0.1rem] text-sm font-semibold"> ! </div>}
    </div>
  );
}
